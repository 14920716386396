import React from 'react';

interface Props {

}

const FailedIcon: React.FC<Props> = () => {

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.56277 0.111992C6.74113 0.185921 6.90326 0.294285 7.03976 0.430894L11.5688 4.95978C11.7056 5.0963 11.814 5.25845 11.8879 5.43697C11.962 5.6155 12 5.8068 12 6.00002C12 6.19323 11.962 6.38454 11.8879 6.56306C11.814 6.74159 11.7056 6.90374 11.5688 7.04026L7.03976 11.5691C6.90326 11.7057 6.74113 11.8141 6.56277 11.888C6.38434 11.962 6.19313 12 6.00001 12C5.8069 12 5.61569 11.962 5.43726 11.888C5.2589 11.8141 5.09677 11.7057 4.96027 11.5691L0.431201 7.04026C0.294507 6.90374 0.18606 6.74159 0.112074 6.56306C0.0380799 6.38454 0 6.19323 0 6.00002C0 5.8068 0.0380799 5.6155 0.112074 5.43697C0.18606 5.25845 0.294507 5.0963 0.431201 4.95978L4.96027 0.430894C5.09677 0.294285 5.2589 0.185921 5.43726 0.111992C5.61569 0.0380552 5.8069 0 6.00001 0C6.19313 0 6.38434 0.0380552 6.56277 0.111992ZM5.4808 7.2533C5.61848 7.391 5.80528 7.4684 6.00001 7.4684C6.19475 7.4684 6.38155 7.391 6.51923 7.2533C6.6569 7.11561 6.7343 6.92878 6.7343 6.73402V3.06215C6.7343 2.86738 6.6569 2.68059 6.51923 2.54287C6.38155 2.40515 6.19475 2.32777 6.00001 2.32777C5.80528 2.32777 5.61848 2.40515 5.4808 2.54287C5.34312 2.68059 5.26573 2.86738 5.26573 3.06215V6.73402C5.26573 6.92878 5.34312 7.11561 5.4808 7.2533ZM5.4808 9.45642C5.61848 9.59412 5.80528 9.67152 6.00001 9.67152C6.19475 9.67152 6.38155 9.59412 6.51923 9.45642C6.6569 9.31873 6.7343 9.1319 6.7343 8.93715C6.7343 8.74239 6.6569 8.55557 6.51923 8.41787C6.38155 8.2801 6.19475 8.20277 6.00001 8.20277C5.80528 8.20277 5.61848 8.2801 5.4808 8.41787C5.34312 8.55557 5.26573 8.74239 5.26573 8.93715C5.26573 9.1319 5.34312 9.31873 5.4808 9.45642Z" fill="#DE350B"/>
    </svg>
  );
};

export default FailedIcon;
