import React from 'react';

interface Props {

}

const SuccessIcon: React.FC<Props> = () => {

  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.001 0.108955L13.5 0.59573L8.10042 8L5.91166 5.83835L6.83857 4.56645L8.10042 5.30239L13.001 0.108955ZM8.61925 0L9.11822 0.486775L3.71865 7.89105L0 4.23358L0.830331 3.42354L3.71865 5.19343L8.61925 0Z" fill="#3497F9"/>
    </svg>
  );
};

export default SuccessIcon;
